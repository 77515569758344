<template>
  <div id="pc">
    <div class="container">
      <div class="header" :class="{scroll:isScroll}">
        <div class="content">
          <img src="./logo.png" alt="" class="logo">
          <img src="./qr.png" alt="" class="qr" @mouseenter="topQrAniFun" @mouseleave="topQrAniFun">
          <img src="./qrImg.png" alt="" class="qrImg" :class="headerQrShow?'highlight': ''">
        </div>
      </div>
      <div class="center">
        <!-- 不用起的用得起 不会用的轻松用 -->
        <div class="blc blc1">
          <div class="wrap">
            <!-- <div class="hehe"></div>
            <div class="hehe1"></div>
            <div class="hehe2"></div> -->
            <img src="./tool.png" alt="" class="tool">
            <!-- <div class="info">
              <img src="./wifi.png" alt="" >
              <p class="wow fadeInLeft">最新版</p>
              <p class="wow fadeInLeft" data-wow-delay="0.1s">无线掌上智能彩超</p>
            </div> -->
            <div class="main">
              <h1 class="wow fadeInUp" data-wow-delay="0.1s">用不起的用得起</h1>
              <h1 class="wow fadeInUp" data-wow-delay="0.2s">不会用的轻松用</h1>
              <ul class="wow fadeInUp" data-wow-delay="0.3s">
                <li>0元入驻</li>
                <li>20秒上手</li>
                <li>检查精准</li>
                <li>报告权威</li>
              </ul>
              <img src="./quick.png" alt="" class="wow fadeInLeft">
            </div>
            <div class="cover"></div>
            <div class="bar">
              <ul>
                <li class="wow fadeInUp" data-wow-delay="0.1s">
                  <h3>最佳100强</h3>
                  <h4>2020年度未来医疗</h4>
                </li>
                <li class="wow fadeInUp" data-wow-delay="0.2s">
                  <h3>TOP10</h3>
                  <h4>2020年度济南创业奖</h4>
                </li>
                <li class="wow fadeInUp" data-wow-delay="0.3s">
                  <h3>入围奖</h3>
                  <h4>2020年度杭州创业大赛</h4>
                </li>
              </ul>
            </div>
          </div>
          <div class="bgBlc"></div>
        </div>
        <!-- 身边即享超声服务 -->
        <div class="blc blc2">
          <div id="lottie"></div>
          <h2 class="wow fadeInUp">身边即享超声服务</h2>
          <ul>
            <li>
              <img src="./check.png" alt="">
              <h3>2分钟检查过程</h3>
            </li>
            <li>
              <img src="./check.png" alt="">
              <h3>10分钟获得报告</h3>
            </li>
          </ul>
          <p>简单易用的操作方式，引导非超声医师2分钟完成检查；<br/> 远端专业医师，快速响应，10分钟出具专业报告</p>
          <div class="btnWrap">
            <button @mouseenter="contentQrAniFun" @mouseleave="contentQrAniFun">联系我们</button>
            <img src="./qrImg.png" alt="" :class="contactQrShow?'highlight': ''">
          </div>
        </div>
        <!-- 多种图像模式 -->
        <div class="blc blc3">
          <div class="content">
            <div class="lPart">
              <van-swipe class="swipe" :autoplay="autoplay" indicator-color="white" ref="swipe" @change="swipeChangeFn">
                <van-swipe-item>
                </van-swipe-item>
                <van-swipe-item>
                </van-swipe-item>
                <van-swipe-item>
                </van-swipe-item>
                <template #indicator>
                  <!-- <div class="custom-indicator">{{ swipeIndex + 1 }}/3</div> -->
                  <ul class="spots">
                    <li :class="swipeIndex==0?'active': ''"></li>
                    <li :class="swipeIndex==1?'active': ''"></li>
                    <li :class="swipeIndex==2?'active': ''"></li>
                  </ul>
                </template>
              </van-swipe>
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="rPart">
              <h2 class="wow slideInUp">多种</h2>
              <h1 class="wow slideInUp">图像模式</h1>
              <div class="info wow slideInUp" data-wow-delay=".1s">
                <img src="./check.png" alt="">
                <h3>3大核心功能点</h3>
              </div>
              <ul>
                <li :class="swipeIndex == 0? 'active': ''" @mouseenter="swipeGoFn($event, 0)" @mouseleave="swipeStartFn()">
                  <h3>适用范围广泛</h3>
                  <p>腹部（肝胆胰脾肾）、血管（颈动脉/上下肢血管</p>
                  <p>浅表（甲状腺/乳腺/淋巴结等）、泌尿（膀胱/输尿管/前列腺）</p>
                  <p>妇科（子宫附件）</p>
                  <span></span>
                </li>
                <li :class="swipeIndex == 1? 'active': ''" @mouseenter="swipeGoFn($event, 1)" @mouseleave="swipeStartFn()">
                  <h3>智能导航</h3>
                  <p>部位识别、方位识别、病理识别</p>
                  <span></span>
                </li>
                <li :class="swipeIndex == 2? 'active': ''" @mouseenter="swipeGoFn($event, 2)" @mouseleave="swipeStartFn()">
                  <h3>多种图像模式</h3>
                  <p>灰阶模式、彩阶模式、频谱模式</p>
                  <span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 影像家园 -->
        <div class="blc blc4">
          <div class="content">
            <ul>
              <li class="lPart">
                <div class="topPart">
                  <h2 class="wow slideInUp">打造基层</h2>
                  <h1 class="wow slideInUp" data-wow-delay=".1s">影像家园</h1>
                  <p class="wow slideInUp" data-wow-delay=".2s">无场地要求、无影像科要求、赋能基层诊所，共创基层影像家园</p>
                  <!-- <div class="mission">
                    <span>进一步了解我们的任务</span><i>></i>
                  </div> -->
                </div>
                <img src="./home2.png" alt="" class="wow bounceInLeft" data-wow-duration="1s">
              </li>
              <li class="rPart">
                <img src="./home1.png" alt="" class="wow bounceInRight" data-wow-duration="1s">
              </li>
            </ul>
          </div>
        </div>
        <!-- 权威认证 -->
        <div class="blc blc5">
          <div class="content">
            <h1 class="wow fadeInUp">权威认证</h1>
            <h3 class="wow fadeInUp" data-wow-delay=".1s">行业认可</h3>
            <ul>
              <li class="wow slideInUp" data-wow-delay=".1s">
                <img src="./pro1.png" alt="">
                <h4>2021年世界人工智能大会</h4>
              </li>
              <li class="wow slideInUp" data-wow-delay=".2s">
                <img src="./pro2.png" alt="">
                <h4>2021年动脉网未来医疗100强</h4>
              </li>
            </ul>
          </div>
        </div>
        <!-- 合作机构 -->
        <div class="blc blc6">
          <div class="content">
            <h1 class="wow fadeInUp">合作机构</h1>
            <h3 class="wow fadeInUp" data-wow-delay=".1s">与多家知名机构合作</h3>
            <ul>
              <li class="wow fadeInDown" data-wow-delay="0.5s">
                <img src="./shjy.png" alt="">
                <h4>上海九院</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="0.6s">
                <img src="./zjzlyy.png" alt="">
                <h4>浙江肿瘤医院</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="0.7s">
                <img src="./rjyy.png" alt="">
                <h4>瑞金医院</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="0.8s">
                <img src="./zdyy.png" alt="">
                <h4>浙大一院</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="0.9s">
                <img src="./mszyy.png" alt="">
                <h4>麻省总医院</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="1s">
                <img src="./shczyy.png" alt="">
                <h4>上海长征医院</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="1.1s">
                <img src="./bjdx.png" alt="">
                <h4>北京大学</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="1.2s">
                <img src="./zky.png" alt="">
                <h4>中科院</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="1.3s">
                <img src="./shjd.png" alt="">
                <h4>上海交大</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="1.4s">
                <img src="./njykdx.png" alt="">
                <h4>南京医科大学</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="1.5s">
                <img src="./glbydx.png" alt="">
                <h4>哥伦比亚大学</h4>
              </li>
              <li class="wow fadeInDown" data-wow-delay="1.6s">
                <img src="./mslg.png" alt="">
                <h4>麻省理工</h4>
              </li>
            </ul>
          </div>
        </div>
        <!-- 我们的掌上超声设备 -->
        <!-- <div class="blc blc7">
          <img src="./map.png" alt="" class="map">
          <h2 class="wow slideInUp" data-wow-delay=".1s">服务赋能</h2>
          <h3 class="wow slideInUp" data-wow-delay=".2s">Soundwise technology</h3>
          <ul>
            <li class="wow slideInUp" data-wow-delay=".3s">
              <img src="./check.png" alt="">
              <h4>守护千万患者</h4>
            </li>
            <li class="wow slideInUp" data-wow-delay=".4s">
              <img src="./check.png" alt="">
              <h4>遍布全国多省市诊所</h4>
            </li>
          </ul>
          <p class="wow slideInUp" data-wow-delay=".5s">我们已服务全国多省市诊所，已帮助千万位患者守护健康</p>
        </div> -->
      </div>
      <div class="footer">
        <h1>我们期待您的加入！</h1>
        <h2>扫描下面二维码访问soundwise的公众号和App</h2>
        <ul>
          <li>
            <img src="./qrImg.png" alt="">
            <h3>公众号</h3>
          </li>
          <li>
            <img src="./qrWeicha.png" alt="">
            <h3>微查App</h3>
          </li>
        </ul>
        <h4><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备19048066号-4</a> <span></span>  Copyright © 2020-2021 版权所有</h4>
        <h5>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502017406" target="_blank">沪公网安备 31011502017406号</a>
        </h5>
        <img src="./footerBg.png" alt="" class="bg">
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import '@vant/touch-emulator'
import animationData from '../../../public/data.json'
export default {
  data() {
    return {
      isScroll: false,
      // 顶部二维码
      headerQrShow: false,
      contactQrShow: false,
      // swipe索引
      swipeIndex: 0,
      // 轮播时间
      autoplay: false,
      show: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandle);
    this.$nextTick(() => {
      let wow = new WOW({
        offset: 0,
        live: false
      })
      wow.init()
    })

    /* eslint-disable */
    var params = {
        container: document.getElementById('lottie'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    var anim;

    anim = lottie.loadAnimation(params);
    /* eslint-enable */
  },
  methods: {
    // 页面滚动事件
    scrollHandle() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 10 ) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    },
    // swipe滚动事件
    swipeChangeFn(index) {
      this.swipeIndex = index
    },
    topQrAniFun() {
      this.headerQrShow = !this.headerQrShow
    },
    contentQrAniFun() {
      this.contactQrShow = !this.contactQrShow
      console.log(this.contactQrShow)
    },
    swipeGoFn(e, num) {
      this.$refs.swipe.swipeTo(num)
      this.autoplay = false
    },
    swipeStartFn() {
      this.autoplay = 3000
    },
  }
}
</script>
<style lang="scss" scoped>
  #pc {
    background: #000001;
    padding-bottom: 36px;
    .container {
      .header {
        height: 100px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        transition: all .5s;
        &.scroll {
          background-color: #000;
        }
        div.content {
          width: 1080px;
          height: 100px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          .logo {
            width: 96px;
            height: 20px;
          }
          .qr {
            width: 18px;
            height: 18px;
            cursor: pointer;
            // background-color: green;
            padding: 7px 0 7px 7px;
            position: relative;
            z-index: 2;
          }
          .qrImg {
            width: 100px;
            height: 102px;
            position: absolute;
            right: 0;
            top: 68px;
            transition: all .5s;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            z-index: 1;
            &.highlight {
              opacity: 1;
              visibility: visible;
              transform: translateY(0px)
            }
          }
        }
      }
      .center {
        .blc {
          margin: 0 auto;
          &.blc1 {
            margin: 0 auto;
            background-color: #000;
            // background-color: red;
            .wrap {
              max-width: 1920px;
              height: 862px;
              overflow: hidden;
              margin: 0 auto;
              background: url('./bg.png') top center no-repeat;
              position: relative;
              .hehe {
                width: 1px;
                background-color: red;
                height: 1000px;
                position: absolute;
                left: 50%;
              }
              .hehe1 {
                width: 1px;
                background-color: red;
                height: 1000px;
                position: absolute;
                left: 50%;
                margin-left: -540px;
              }
              .hehe2 {
                width: 1px;
                background-color: red;
                height: 1000px;
                position: absolute;
                left: 50%;
                margin-left: 540px;
              }
              .info {
                position: absolute;
                left: 50%;
                top: 249px;
                margin-left: -540px;
                img {
                  width: 24px;
                  height: 24px;
                  display: block;
                  margin-bottom: 13px;
                }
                p {
                  line-height: 22px;
                  color: #fff;
                  font-size: 16px;
                  margin-bottom: 3px;
                }
              }
              .main {
                position: absolute;
                top: 237px;
                left: 50%;
                margin-left: -272px;
                h1 {
                  font-size: 46px;
                  line-height: 56px;
                  font-weight: 500;
                  color: #fff;
                  &:first-child {
                    margin-bottom: 10px;
                  }
                }
                ul {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;
                  margin-bottom: 12px;
                  li {
                    font-size: 18px;
                    line-height: 25px;
                    color: #fff;
                  }
                }
                img {
                  width: 24px;
                  height: 14px;
                  display: block;
                }
              }
              .tool {
                width: 302px;
                height: 556px;
                position: absolute;
                left: 50%;
                margin-left: 250px;
                top: 125px;
              }
              .cover {
                height: 319px;
                width: 100%;
                background: url('./cover.png') no-repeat;
                background-size: 100% 100%;
                // background: blue;
                // opacity: .5;
                position: absolute;
                left: 0;
                bottom: 0;
                // margin-bottom: -127px;
              }
              .bar {
                // display: none;
                margin: 0 auto;
                width: 1200px;
                height: 160px;
                border-radius: 12px;
                position: absolute;
                background: url(./barBg.png) center center;
                // background-color: yellow;
                overflow: hidden;
                bottom: 0px;
                left: 50%;
                margin-left: -600px;
                margin-bottom: 127px;
                ul {
                  display: flex;
                  li {
                    flex: 1;
                    height: 160px;
                    text-align: center;
                    h3 {
                      color: #fff;
                      font-size: 32px;
                      font-weight: 600;
                      line-height: 45px;
                      margin-top: 43px;
                    }
                    h4 {
                      font-size: 18px;
                      color: #fff;
                      line-height: 25px;
                    }
                  }
                }
              }
            }
          }
          &.blc2 {
            height: 900px;
            width: 980px;
            background-color: blue;
            margin: 0 auto;
            position: relative;
            h2 {
              font-size: 48px;
              width: 395px;
              font-weight: 500;
              line-height: 67px;
              color: #fff;
              position: relative;
              z-index: 2;
              margin: 0 auto;
              padding-top: 78px;
            }
            ul {
              width: 395px;
              position: relative;
              z-index: 2;
              // background-color: green;
              margin: 20px auto;
              li {
                height: 37px;
                img {
                  width: 16px;
                  height: 14px;
                  display: inline-block;
                  margin-right: 8px;
                }
                h3 {
                  font-size: 26px;
                  color: #fff;
                  line-height: 37px;
                  font-weight: 600;
                  display: inline-block;
                  letter-spacing: 2px;
                }
              }
            }
            p {
              font-size: 14px;
              color: #fff;
              opacity: .7;
              line-height: 22px;
              position: relative;
              z-index: 2;
              width: 395px;
              margin: 0 auto;
            }
            #lottie{
              background-color:#000000;
              width:100%;
              height:100%;
              display:block;
              overflow: hidden;
              transform: translate3d(0,0,0);
              text-align: center;
              opacity: 1;
              position: absolute;
              left: 0;
              right: 0;
              z-index: 1;
            }
            .btnWrap {
              width: 395px;
              position: relative;
              z-index: 2;
              margin: 20px auto 0;
              // background-color: red;
              button {
                width: 96px;
                height: 32px;
                background: #267BFF;
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                border: 0 none;
                color: #fff;
                cursor: pointer;
              }
              img {
                position: absolute;
                left: 0;
                top: 40px;
                width: 100px;
                height: 102px;
                visibility: hidden;
                transition: all .3s;
                opacity: 0;
                transform: translateY(-10px);
                &.highlight {
                  visibility: visible;
                  opacity: 1;
                  transform: translateY(0px);
                }
              }
            }
          }
          &.blc3 {
            width: 100%;
            // background-color: blue;
            padding-top: 74px;
            margin-bottom: 99px;
            .content {
              width: 1080px;
              height: 700px;
              margin: 0 auto;
              display: flex;
              justify-content: space-between;
              // background-color: green;
              .lPart {
                height: 731px;
                width: 340px;
                .swipe {
                  cursor: default;
                  .van-swipe-item {
                    background-color: #39a9ed;
                    width: 340px;
                    height: 680px;
                    &:nth-child(1) {
                      background:url(./software1.png) center no-repeat;
                    }
                    &:nth-child(2) {
                      background:url(./software2.png) center no-repeat;
                    }
                    &:nth-child(3) {
                      background:url(./software3.png) center no-repeat;
                    }
                    // img {
                    //   width: 340px;
                    //   height: 680px;
                    //   display: block;
                    // }
                  }
                  .spots {
                    text-align: center;
                    margin-top: 30px;
                    li {
                      width: 7.2px;
                      height: 7.2px;
                      background-color: #d8d8d8;
                      display: inline-block;
                      margin: 0 4px;
                      border-radius: 50%;
                      transition: all .3s;
                      &.active {
                        border-radius: 6px;
                        width: 24px;
                        background-color: #267BFF;
                      }
                    }
                  }
                }
              }
              .rPart {
                h2 {
                  font-size: 40px;
                  line-height: 56px;
                  color: #fff;
                  font-weight: 500;
                }
                h1 {
                  font-size: 48px;
                  line-height: 56px;
                  color: #fff;
                  font-weight: 500;
                  margin-bottom: 33px;
                }
                .info {
                  margin-bottom: 62px;
                  img {
                    width: 16px;
                    height: 14px;
                    // border: 1px dashed #ccc;
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 8px;
                    margin-top: 4px;
                  }
                  h3 {
                    display: inline-block;
                    font-size: 26px;
                    font-weight: 500px;
                    line-height: 22px;
                    letter-spacing: 1px;
                    color: #fff;
                  }
                }
                ul {
                  li {
                    width: 440px;
                    padding: 20px 25px;
                    background: #121212;
                    border-radius: 2px;
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 40px;
                    overflow: hidden;
                    &:hover,
                    &.active {
                      span {
                        transform: translateY(-5px);
                      }
                    }
                    h3 {
                      font-size: 22px;
                      font-weight: 400;
                      line-height: 30px;
                      color: #fff;
                      margin-bottom: 8px;
                    }
                    p {
                      font-size: 14px;
                      font-weight: 300px;
                      line-height: 26px;
                      color: #fff;
                    }
                    span {
                      width: 100%;
                      height: 5px;
                      background: #2678FF;
                      position: absolute;
                      bottom: -5px;
                      left: 0;
                      transition: all .3s;
                    }
                  }
                }
              }
            }
          }
          &.blc4 {
            // background-color: blue;
            .content {
              // background-color: green;
              width: 1080px;
              margin: 0 auto;
              ul {
                display: flex;
                justify-content: space-between;
                li {
                  &.lPart {
                    // background-color: red;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    img {
                      width: 500px;
                      height: 475px;
                    }
                    .topPart {
                      h2 {
                        font-size: 40px;
                        font-weight: 500;
                        color: #fff;
                        line-height: 56px;
                        margin-bottom: 4px;
                      }
                      h1 {
                        font-size: 56px;
                        line-height: 72px;
                        font-weight: 500;
                        color: #fff;
                        // margin-bottom: 12px;
                      }
                      p {
                        color: #fff;
                        font-size: 16px;
                        line-height: 30px;
                        font-weight: 300;
                        margin-bottom: 10px;
                      }
                      .mission {
                        font-size: 14px;
                        color: #267BFF;
                        line-height: 20px;
                        font-weight: 300;
                        span {
                          padding-right: 4px;
                          border-bottom: 1px solid #267BFF;
                        }
                      }
                    }
                  }
                  &.rPart {
                    img {
                      width: 510px;
                      height: 706px;
                      display: block;
                    }
                  }
                }
              }
            }
          }
          &.blc5 {
            .content {
              width: 1080px;
              margin: 0 auto;
              padding-top: 71px;
              h1 {
                font-size: 48px;
                font-weight: 500;
                line-height: 67px;
                letter-spacing: 1px;
                color: #fff;
                text-align: center;
                margin-bottom: 10px;
              }
              h3 {
                font-size: 18px;
                font-weight: 300;
                color: #fff;
                line-height: 22px;
                text-align: center;
                margin-bottom: 48px;
              }
              ul {
                display: flex;
                justify-content: space-between;
                li {
                  width: 520px;
                  img {
                    width: 520px;
                    height: 260px;
                    margin-bottom: 20px;
                  }
                  h4 {
                    font-size: 18px;
                    line-height: 25px;
                    color: #BCBCBC;
                    text-align: center;
                  }
                }
              }
            }
          }
          &.blc6 {
            // background-color: blue;
            padding: 70px 0 38px;
            .content {
              // background-color: green;
              width: 1080px;
              // height: 700px;
              margin: 0 auto;
              h1 {
                font-size: 48px;
                font-weight: 500px;
                line-height: 67px;
                text-align: center;
                color: #fff;
                margin-bottom: 10px;
              }
              h3 {
                font-size: 22px;
                font-weight: 300;
                color: hsla(0, 0%, 100%, 1);
                line-height: 22px;
                text-align: center;
                margin-bottom: 50px;
              }
              ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                li {
                  width: 16.6666%;
                  text-align: center;
                  margin-bottom: 45px;
                  img {
                    width: 57px;
                    height: 57px;
                    margin-bottom: 10px;
                  }
                  h4 {
                    font-size: 16px;
                    font-weight: 400px;
                    color: #fff;
                    line-height: 22px;
                  }
                }
              }
            }
          }
          &.blc7 {
            width: 1080px;
            height: 495px;
            // background-color: green;
            position: relative;
            margin: 0 auto 70px;
            .map {
              width: 600px;
              height: 495px;
            }
            h2 {
              font-size: 42px;
              font-weight: 500;
              line-height: 59px;
              color: #fff;
              position: absolute;
              top: 92px;
              left: 660px;
            }
            h3 {
              font-size: 14px;
              line-height: 20px;
              color: #fff;
              position: absolute;
              top: 156px;
              left: 660px;
            }
            ul {
              position: absolute;
              left: 660px;
              top: 233px;
              li {
                img {
                  width: 16px;
                  height: 14px;
                  display: inline-block;
                  margin-right: 8px;
                }
                h4 {
                  font-size: 26px;
                  line-height: 37px;
                  color: #fff;
                  display: inline-block;
                }
              }
            }
            p {
              position: absolute;
              top: 345px;
              left: 660px;
              font-size: 14px;
              font-weight: 300;
              color: #fff;
              width: 350px;
              line-height: 22px;
              opacity: .7;
            }
          }
        }
      }
      .footer {
        width: 1080px;
        height: 470px;
        background: #161719;
        margin: 0 auto;
        border-bottom: 8px solid #375DDE;
        position: relative;
        h1 {
          font-size: 36px;
          line-height: 50px;
          font-weight: 500px;
          color: #fff;
          position: absolute;
          top: 100px;
          left: 120px;
        }
        h2 {
          font-size: 16px;
          color: #fff;
          line-height: 22px;
          position: absolute;
          left: 120px;
          top: 162px;
        }
        ul {
          position: absolute;
          left: 120px;
          top: 256px;
          li {
            display: inline-block;
            &:first-child {
              margin-right: 61px;
            }
            img {
              width: 100px;
              height: 100px;
            }
            h3 {
              font-size: 15px;
              line-height: 21px;
              color: #fff;
              text-align: center;
              margin-top: 5px;
            }
          }
        }
        h4, h5 {
          top: 410px;
          left: 120px;
          position: absolute;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #666;
          span {
            display: inline-block;
            width: 10px;
            height: 1px;
          }
          a {
            color: #666;
          }
        }
        h5 {
          top: 434px;
        }
        .bg {
          position: absolute;
          top: 49px;
          right: 101px;
          width: 278px;
          height: 421px;
        }
      }
    }
  }
</style>
